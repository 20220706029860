/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// require('./theme.min');


// any CSS you require will output into a single css file (app.css in this case)
//require('../vendor/bootstrap-icons/font/bootstrap-icons.css');
// require('../vendor/hs-mega-menu/dist/hs-mega-menu.min.css');
//require('../vendor/aos/dist/aos.css');
require('../css/theme.min.css');
require('../css/custom.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');

// console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
